import React from "react";
import "./SolutionPage.css";
import Header from "../../../Component/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";

const SolutionPage = () => {
  return (
    <>
      <Header />
     
      <Footer />
    </>
  );
};

export default SolutionPage;
