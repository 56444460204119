import React from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import "./about.css";
import Aboutimg1 from "./assets/1.png";
import Aboutimg2 from "./assets/2.png";
import Aboutimg3 from "./assets/3.png";
import Aboutimg4 from "./assets/4.png";
import Aboutimg5 from "./assets/Jesus 1.png";
import Aboutimg6 from "./assets/Jesus 2.png";
import Aboutimg7 from "./assets/Jesus 3.png";
import Aboutimg8 from "./assets/Jesus 4.png";
import Aboutimg9 from "./assets/Jesus 5.png";
import Banner from "./assets/banner.png";
import Marquee from "react-fast-marquee";

import CardImg1 from "./assets/2.1.png";
import CardImg2 from "./assets/2.2.png";
import CardImg3 from "./assets/2.3.png";
import CardImg4 from "./assets/2.4.png";
import Applytoday from "../../Component/Applytoday/Applytoday";

const About = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row aboutheading mb-3 mt-5">
          <div className="heading-container col-12 col-md-12 col-lg-12 mt-5">
            <h1 className="aboutfontfamily">
              About <span className="aboutmspan px-1">Stephen's</span>
            </h1>
            <div className="rounded-line"></div>
          </div>
        </div>

        <div className="row about1p  mb-5">
          <p>
            <span className="aboutspan1 fw-bold">Found in 2016</span>, we have
            grown and evolved, continually adapting to industry trends and
            technological <br className="breakless" />
            advancements to offer cutting-edge client solutions. We understand
            that every brand has a unique data
            <br className="breakless" />
            footprint that can be leveraged through AI.
          </p>
          <p>
            Our goal is to bring that data to life through innovative formula's,
            thoughtful strategy, and a deep
            <br className="breakless" />
            understanding of your business goals.
          </p>
          <p>
            Our collaborative approach ensures that your vision is at the
            forefront of everything we create. We work
            <br className="breakless" />
            closely with you to understand your brand's essence, values, and
            target audience, translating these
            <br className="breakless" />
            insights into a cohesive and impactful-profitable business
            solutions.
          </p>
          <p>Partner with us. We are eager to discuss growth.</p>
        </div>

        <div className="row ddd" style={{ backgroundColor: "whitesmoke" }}>
          <h4 className="mt-5 aboutheading1">
            How do we aid to Fully Embrace Data-Driven Decision Making?
          </h4>

          <div className="bannerlarge mt-4">
            <img
              src={Banner}
              alt="main banner"
              className="bannerimg img-fluid"
            />
          </div>

          <div className="row mt-4 mb-2 bannerabout">
            <div className="col-12 bannerabout">
              <img src={CardImg1} alt="" srcset="" className="img-fluid" />
            </div>
          </div>
          <div className="row  mb-2 bannerabout">
            <div className="col-12 bannerabout">
              <img src={CardImg2} alt="" srcset="" className="img-fluid" />
            </div>
          </div>
          <div className="row mb-2 bannerabout">
            <div className="col-12 bannerabout">
              <img src={CardImg3} alt="" srcset="" className="img-fluid" />
            </div>
          </div>
          <div className="row  mb-2 bannerabout">
            <div className="col-12 bannerabout">
              <img src={CardImg4} alt="" srcset="" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-5 foundermainrow">
          <div className="founderrow d-flex align-items-center mb-3">
            <div className="rounded-line2 me-5 smline2"></div>
            <h3
              style={{
                color: "#276195",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
              className="meetc "
            >
              Meet Our Founder
            </h3>
            <div className="rounded-line2  smline3"></div>
          </div>

          <div className="row justify-content-center mt-4">
            <Marquee>
              <div className="col-auto">
                <img
                  src={Aboutimg5}
                  alt="aboutimg5"
                  className="aboutimg2 me-4"
                />
              </div>
              <div className="col-auto">
                <img
                  src={Aboutimg6}
                  alt="aboutimg6"
                  className="aboutimg2 me-4"
                />
              </div>
              <div className="col-auto">
                <img
                  src={Aboutimg7}
                  alt="aboutimg7"
                  className="aboutimg2 me-4"
                />
              </div>
              <div className="col-auto">
                <img
                  src={Aboutimg8}
                  alt="aboutimg8"
                  className="aboutimg2 me-4"
                />
              </div>
              <div className="col-auto">
                <img
                  src={Aboutimg9}
                  alt="aboutimg9"
                  className="aboutimg2 me-4"
                />
              </div>
            </Marquee>
          </div>
        </div>

        <div className="row d-flex align-items-center justify-content-center">
          <div className="broadheading mt-5">
            <h4 className="text-center">Is the term analytics overwhelming for you?</h4>
          </div>
        </div>

        <div className="row mt-3">
          <div className="d-flex flex-column align-items-center justify-content-center poem mt-4 mb-5 fontfamily">
            <p
              style={{ fontStyle: "italic", fontFamily: "Montserrat" }}
              className=" py-3"
            >
              Let's sing a song
            </p>
            <p>
              God will make a way
              <br />
              Where there seems to be no way
              <br />
              He works in ways we cannot see
              <br />
              He will make a way for me
            </p>
            <p>
              He will be my guide
              <br />
              Hold me closely to His side
              <br />
              With love and strength for each new day
              <br />
              He will make a way for me
            </p>
            <p>
              By a roadway in the wilderness, He'll lead me
              <br />
              Rivers in the desert will I see
              <br />
              Heaven and earth will fade but His Word will still remain
              <br />
              <span className="poemspan">He will do something new today.</span>
              😎
            </p>
          </div>
        </div>

        <div className="row">
          <div className="quote1 d-flex align-items-center flex-column mb-5">
            <h6 style={{ fontWeight: "bold" }}>
              God did not bring you this far to leave you or forsake you.
            </h6>
            <p>
              "For I know the plans I have for you,
              <span className="quotespan1">
                " declares the Lord, "plans to
                <br className="breakless" />
                prosper you and not to harm you, plans to give you hope and a
                <br className="breakless" />
                future.".
              </span>{" "}
              <span className="quotespan2">Jeremiah 29:11</span>
            </p>
          </div>
          <div className="borderc mt-3"></div>
        </div>

        <div className="row">
          <div className="poem2 mt-5 mb-5">
            <h4 className="mb-3 mt-3">
              <span
                style={{
                  color: "#276195",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
                className="smallpray"
              >
                A prayer for you.
              </span>
              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "15px",
                  fontFamily: "arial",
                }}
                className="ms-3 smallpray2"
              >
                we hope you find it encouraging.
              </span>
            </h4>
            <p style={{ lineHeight: "2.5" }}>
              Heavenly Father,
              <br />I pray for everyone who has arrived to this page.
            </p>
            <p style={{ lineHeight: "1.2" }}>
              We thank you for the business growth you have already planned for
              them.
              <br />
              In due time, may it come to them.
            </p>
            <p>
              Water their hearts, O Lord.Let your love bloom like that of Garden
              of Eden.
            </p>
            <p className="mt-5">
              In the name of Jesus, we lift this prayer. Amen.
            </p>
          </div>
        </div>

        <div
          className="row d-flex align-items-center justify-content-center mt-5"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="broadheading2">
            <h3 className="text-center">Come join us to multiply your business.</h3>
          </div>
          <div className="text-center mt-5 mb-5 broadheading3">
            <h3>
              {" "}
              <span className="aboutfontfamily">Mission statement at</span>{" "}
              <span style={{ color: "#276195", fontWeight: "bold" }}>
                Stephen's Analytics
              </span>
            </h3>
            <p
              style={{ color: "green", textAlign: "center" }}
              className="mt-4 fw-bold"
            >
              To be a catalyst for innovation and progress,
         
              fostering a culture of curiosity and evolving design
           
              thinking to manage change respectively.
            </p>
          </div>
        </div>
      
          <Applytoday />
        
      </div>

      <Footer />
    </>
  );
};

export default About;
