import React from "react";
import "./Article.css";
import logoimg from "./Assets/SHOD Logo WHITE.png";
import Video from "./Assets/Modi Video.mp4";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../../Component/Header/Header";
import News from "../../../../Component/News/News";
import Footer from "../../../../Component/Footer/Footer";

const Article = () => {
  return (
    <>
      <Header />
      <div className="container-fluid news">
        {/* <h2></h2> */}
        <div className="container news2">
          <div className="row article-head ">
            <div className="article-img">
              <img src={logoimg} alt=""></img>
              <p className="article-date">
                Article Release Date : 02 NOV 2018 7:21PM, Delhi, India.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-md-12 "></div>
              <div className="col-lg-10 col-md-12 ">
                <div className="live">
                  <h5>
                    With the launch of MSME, Prime Minister Shri Narendra Modi
                    views Stephen’s House of Design's commercial project, CANDI,
                    showcasing Canara Bank's Digital Banking Branches. Delhi,
                    2022
                    <span> LIVE</span>
                  </h5>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-12 ">
                    <div className="article-video">
                      <video
                        className="article-video1"
                        src={Video}
                        loop
                        autoPlay
                        muted
                      >
                        Your browser does not support HTML video.
                      </video>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <p className="modi-para">
                      Prime Minister Shri Narendra Modi at the launch, viewing
                      Canara Bank’s Digital Bank’s promotional video, a project
                      undertaken by Stephen's House.
                    </p>
                  </div>
                </div>
                <div className="nxt-para">
                  <p>
                    The event, held in Delhi, showcased Stephen’s House of
                    Design's commercial project, CANDI, showcasing Canara Bank's
                    Digital Banking Branches, which was displayed for the Prime
                    Minister's review. The innovative design highlighted the
                    integration of modern banking technologies with user-centric
                    aesthetics
                  </p>
                  <p>
                    Additionally, Stephen’s managed the above event in Delhi,
                    leaving a lasting impression on attendees and stakeholders.{" "}
                    They transformed Canara Bank's display space into an
                    interactive journey by creating a 3D hologram of their
                    digital offerings. This hologram, a physical structure that
                    diffracts light to form an image, seamlessly integrated
                    technology and interactivity.
                  </p>
                  <p>
                    At the outset of the project, SHOD worked closely with the
                    Indian government to define clear objectives and craft a{" "}
                    strategic vision for the exhibition, all for our esteemed
                    visitor, Mr. Narendra Modi - Current Prime Minister of
                    India, since 2014.
                  </p>
                  <p>
                    The event, held in Delhi, highlighted the government’s
                    commitment to enhancing the MSME ecosystem, which is vital
                    for economic growth and job creation. The program is
                    expected to offer various forms of assistance, including
                    financial support, technological upgrades, and market
                    access, to help MSMEs overcome challenges and scale their
                    operations. Additionally, the event served as an opportunity
                    to educate attendees on the bank's commitment to innovation
                    and financial literacy.
                  </p>
                  <p>
                    The Prime Minister said that the 12 decisions that he is
                    announcing today, will mark a new chapter for the MSME
                    sector. Noting that MSMEs are one of the principal
                    employment generators in India, the Prime Minister recalled
                    the glorious Indian traditions of small scale industry,
                    including Ludhiana’s hosiery, and Varanasi’s sarees.
                  </p>
                  <p>
                    The Prime Minister said that the success of economic reforms
                    launched by the Union Government, can be gauged from the
                    rise in India's “Ease of Doing Business Rankings,” from 142
                    to 77 in four years.
                  </p>
                  <p>
                    In flawlessly executing the exhibition management for Canara
                    Bank, Stephen’s has once again showcased its ability to
                    achieve exceptional results and surpass client expectations.
                    Through strategic planning, creative innovation, and
                    meticulous attention to detail, the team has elevated the
                    exhibition experience, setting a new standard for excellence{" "}
                    in the banking industry and beyond.
                  </p>
                  <p className="space">
                    Tap on to view full length MSME news from the PMO India{" "}
                    <br />
                    <span>
                      {" "}
                      <a
                        href="https://www.youtube.com/live/jBXyfXNk-Jk?si=0fO2Qn_RwvZjPoZi"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.youtube.com/live/jBXyfXNk-Jk?si=0fO2Qn_RwvZjPoZi
                      </a>
                    </span>
                  </p>
                  <p className="space">
                    Tap on to view the commercial interior design project done
                    by <br />
                    <span>
                      <a
                        href="https://youtu.be/Ue5iJefqonk?si=2plK5nxXa7se-njH"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SHOD https://youtu.be/Ue5iJefqonk?si=2plK5nxXa7se-njH
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-md-12 "></div>
            </div>
          </div>
        </div>
      </div>
      <News />
      <Footer />
    </>
  );
};

export default Article;
