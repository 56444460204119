import React from "react";
import "./Open.css";
import Header from "../../../../../Component/Header/Header";
import post from "./Assets/post-image-1.png";
import videocase from "./Assets/Use Cases.mp4";
import tools from "./Assets/Tools.png";
import Helath from "./Assets/IconHealthCare.png";
import finanical from "./Assets/IconFinancial.png";
import enegry from "./Assets/IconEnegry.png";
import insurance from "./Assets/IconInsurance.png";
import manufacturing from "./Assets/IconManufacturing.png";
import retail from "./Assets/IconRetail.png";
import life from "./Assets/IconLifeScience.png";
import sector from "./Assets/IconPublicSector.png";
import Footer from "../../../../../Component/Footer/Footer";
import News from "../../../../../Component/News/News";

const Open = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="video-container">
            <video className="video1" src={videocase} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
          </div>
        </div>

        <div className="row  mt-lg-5">
          <div className="col-lg-8 col-md-12  contain">
            <div>
              <div className="d-none d-lg-inline">
                <img className="img-tool img-fluid" src={tools} alt="" />
              </div>
              <h1 className="h1-edit1 mx-lg-2">
                Before you start building, let us understand how can analytics
                help in different industries.
              </h1>
            </div>

            <p className="content2 mx-2">
              A good dashboard design presents business insights derived from
              data in a way that's easy to understand. In a well-designed
              dashboard, the different components seamlessly mesh with one
              another to provide views of the data that give end users
              actionable insights for data-driven decisions and operations
              tasks, while also allowing them to drill down to see more granular
              data if needed.
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-8 col-md-12 contain">
            <h1 className="h1-edit3 mx-2 py-3">Use Cases</h1>
            <h4 className="h4-edits mx-2">
              <span className="span-edit1">
                A wide range of Industries and Job roles leverage AI analytics
                techniques. Here are some
                <span className="d-none d-lg-inline">
                  <br />
                </span>
                <span className="span-edit2">
                  common predictive analytics examples across different
                  industries.
                </span>
              </span>
            </h4>
          </div>
        </div>

        <div className="row justify-content-center infoblock my-4">
          <div className="use-case1 col-lg-4 col-md-4 mb-4">
            <i className="icon insurance-icon">
              <img
                className="imageusecase"
                src={insurance}
                alt="Insurance Icon"
              />
            </i>
            <h4 className="h4_heading">Insurance companies</h4>
            <p>
              may use AI analytics to assess and predict risks associated with
              policy applications and determine the likelihood of future claims.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4 use-case2">
            <i className="icon financial-icon">
              <img
                className="imageusecase"
                src={finanical}
                alt="Financial Icon"
              />
            </i>
            <h4 className="h4_heading">Financial service firms</h4>
            <p>
              can utilize it to predict loan default probabilities, detect
              fraud, and forecast market movements for better investment
              decisions.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4">
            <i className="icon retail-icon">
              <img className="imageusecase" src={retail} alt="Retail Icon" />
            </i>
            <h4 className="h4_heading">Retailers and CPG companies</h4>
            <p>
              can leverage it to analyze past promotion effectiveness and
              predict which offers will be most effective in the future.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4 use-case2">
            <i className="icon healthcare-icon">
              <img
                className="imageusecase"
                src={Helath}
                alt="Healthcare Icon"
              />
            </i>
            <h4 className="h4_heading">Healthcare companies</h4>
            <p>
              may employ it to forecast patient admissions and readmissions,
              enabling better management of patient care and resource
              allocation.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4">
            <i className="icon energy-icon">
              <img className="imageusecase" src={enegry} alt="Energy Icon" />
            </i>
            <h4 className="h4_heading">Energy and utilities sectors</h4>
            <p>
              can utilize it to analyze historical equipment failures and
              predict future energy demands based on past consumption patterns.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4 use-case2">
            <i className="icon life-sciences-icon">
              <img
                className="imageusecase"
                src={life}
                alt="Life Sciences Icon"
              />
            </i>
            <h4 className="h4_heading">Life sciences organizations</h4>
            <p>
              can employ it to develop patient personas and predict the
              likelihood of non-adherence to treatment.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4 ucbs">
            <i className="icon manufacturing-icon">
              <img
                className="imageusecase"
                src={manufacturing}
                alt="Manufacturing Icon"
              />
            </i>
            <h4 className="h4_heading">
              Manufacturing and supply chain operations
            </h4>
            <p>
              can use it to forecast demand, optimize inventory management, and
              identify factors leading to production failures.
            </p>
          </div>

          <div className="use-case1 col-lg-4 col-md-4 mb-4">
            <i className="icon public-sector-icon">
              <img
                className="imageusecase"
                src={sector}
                alt="Public Sector Icon"
              />
            </i>
            <h4 className="h4_heading">The public sector</h4>
            <p>
              can leverage it to analyze population trends, plan infrastructure
              investments, and make informed decisions for public works
              projects.
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12 col-12 col-md-12 Open_Gain_Content">
            <div>
              <h1 className="py-2">
                Gain Valuable Insights with the
                <span className="d-none d-lg-inline">
                  <br />
                </span>
                Assistance of Power BI Experts
              </h1>

              <ul>
                <li>
                  Integrate high-impact analytics and business intelligence
                  product with minimal upfront costs.
                </li>
                <li>Understand project outcome and pricing.</li>
              </ul>
              <div className="btn_160">
                <button className="btn  ">Connect me to an expert</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <News />
      <Footer />
    </>
  );
};

export default Open;
