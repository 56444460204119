import React from "react";
import "./DataProtection.css";
import data from "./Assets/Files.png";
import Header from "../../../../Component/Header/Header";
import News from "../../../../Component/News/News";
import Footer from "../../../../Component/Footer/Footer";

const DataProtection = () => {
  return (
    <>
      <Header />
      <div className="container-fluid my-5  gapps container4">
        <div className="col-lg-12 col-md-12 col-12 class">
          <div className="row d-flex data_pvt">
            <div className="col-lg-6 data_pvt1">
              <h2 className=" data_prtct mb-3">
                <strong>
                  Data Protection <span className="text-muted">|</span>{" "}
                  <span className="text-muted_clr">Policy</span>
                </strong>{" "}
              </h2>
            </div>
            <div className="col-lg-4 ">
              <div className="datapic">
                <img src={data} alt="prtcimage"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className=" privacy_txt col-10">
            <p>
              <strong>Your Privacy</strong>
            </p>
            <p className="privacy_txtpar">
              Stephen’s House of Analytics is committed to protecting your
              privacy. This data protection & privacy policy sets out how we use
              and protect the information you give us. The data security policy
              applies to all customer data, personal data, or other company data
              defined as sensitive by the company's data classification policy.
              Therefore, it applies to every server, database, and system that
              handles such data, including any device that is regularly used for
              email, web access, or other work-related tasks. Every user who
              interacts with Stephen’s House of Analytics company IT services is
              also subject to this policy.
            </p>
          </div>

          <div className=" privacy_Overview col-10">
            <p>
              <strong>Overview</strong>
            </p>
            <p>
              Purpose - Stephen’s House of Analytics is entrusted with the
              responsibility to provide professional legal advice to clients who
              provide us with confidential information.
            </p>
            <p>
              Inherent in this responsibility is an obligation to provide
              appropriate protection against theft of data and malware threats,
              such as viruses, and spyware applications.
            </p>
            <p>
              The purpose of this policy is to establish standards for the base
              configuration of equipment that is owned and/or operated by
              Stephen’s House of Analytics or equipment that accesses Stephen’s
              House of Analytics internal systems.
            </p>
            <p>
              Implementation of this policy minimizes unauthorized access to
              Stephen’s House of Analytics information and technology and
              protects confidential client information.
            </p>
            <p>
              Contact Stephen’s House of Analytics to access our Data Protection
              Policy.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 let_container">
            <div className="col-10  let_text-center">
              <h4>Let's Transform Your Data into a Strategic Advantage</h4>
              <p>
                Ready to take your data to the next level? Contact Stephen’s
                House of Analytics today and discover how we can help you make
                smarter decisions, drive growth, and achieve your business
                goals.
              </p>
              <button className=" btn-primary_btn">Connect me</button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <News />
      <Footer />
    </>
  );
};

export default DataProtection;
