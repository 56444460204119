import React from "react";
import "./ModernSupplySolutions.css";
import Header from "../../../../Component/Header/Header";
import vedio1 from "./Assets/1.mp4";
import img1 from "./Assets/1.png";
import img2 from "./Assets/2.png";
import img3 from "./Assets/3.png";
import img4 from "./Assets/4.png";
import img5 from "./Assets/5 (2).png";
import img6 from "./Assets/6.png";
import imgss1 from "./Assets/Services_FlashCard-05-removebg-preview.png";
import Footer from "../../../../Component/Footer/Footer";
import News from "../../../../Component/News/News";
import Applytoday from "../../../../Component/Applytoday/Applytoday";

const ModernSupplySolutions = () => {
  return (
    <>
      <Header />
      <div className="container-fluid Container_Modern">
        <div className="row">
          <div className="col-12 my-3 Modern_Solution_Content1 text-center">
            <h1 className="fw-bold">
              Solving Complex Challenges in the Modern Supply Chain
            </h1>
            <p>
              Building the Backbone of Data Excellence that shall empower your
              businesses
            </p>
          </div>
        </div>

        <div className="row">
          <div className="video-container2">
            <video className="video2" src={vedio1} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
          </div>
        </div>

        <div className="row">
          <div className="col-12 Modern_Solution_Content2">
            <h4 className="fw-bold text-center">
              Why settle for ordinary when you can strategically outpace the
              competition?
            </h4>
            <p className="modern_ptag">
              To thrive in today’s digital-first world, you need speed,
              visibility, automation, and resilience.
            </p>
            <p>
              Our comprehensive portfolio of digital transformation
              services—encompassing strategy, design, development, and
              implementation—positions us to help you create a smarter, more
              agile digital enterprise.
            </p>
          </div>
        </div>

        <div className="container mb-4">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div
                className="card cardtemp cardtemp1 border-0"
                style={{ borderRadius: "15px" }}
              >
                <p className="mx-3 mt-3 fw-bold">STRATEGISE</p>
                <img src={img1} alt="" className=" " />
                <h4 className="mx-3 fw-bold">Digital Consulting</h4>
                <span className="mx-3">
                  Shaping Smarter Digital Organisations
                </span>
                <div className="card cardtempitem">
                  <h4 className="card_title mt-3 fw-bold">
                    Digital Consulting
                  </h4>
                  <h6 className="card_text">
                    Shaping Smarter Digital Organisations
                  </h6>
                  <p className="span_text">
                    Disruption and change are everyday realities
                    <br /> when managing a supply chain.Removing the <br />
                    vulnerabilities and risks associated with <br />
                    change requires thoughtful and dynamic
                    <br />
                    strategies.
                  </p>
                  <p>
                    Speed up your transformation with our <br />
                    assessment and advisory services, proven
                    <br /> methodologies, proprietary tools, and change
                    <br /> management expertise.
                  </p>
                </div>
              </div>
            </div>

            {
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                <div
                  className="card cardtemp cardtemp2 border-0"
                  style={{ borderRadius: "15px" }}
                >
                  <p className="mx-3  mt-3 fw-bold">DESIGN AND BUILD</p>
                  <img src={img2} alt="" className="Card_imge" />
                  <h4 className="mx-3 fw-bold">Analytics & AI</h4>
                  <span className="mx-3 fw-bold">
                    Revealing Key Insights and Enhancing Visibility
                  </span>
                  <div className="card cardtempitem">
                    <h4 className="card_title001">Analytics & AI</h4>
                    <h6 className="card_text1">
                      Revealing Key Insights and Enhancing Visibility
                    </h6>
                    <p className="span_text">
                      Businesses generate terabytes of data daily,
                      <br /> yet siloed operations. Many organisations find
                      <br /> themselves information-rich but insights-poor.
                    </p>
                    <p>
                      Leverage your data to unlock its full potential,
                      <br /> enhance visibility, and drive data-driven
                      <br /> decisions with our suite of AI-powered <br />
                      capabilities and solutions.
                    </p>
                  </div>
                </div>
              </div>
            }

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div
                className="card cardtemp cardtemp3 border-0"
                style={{ borderRadius: "10px" }}
              >
                <p className="mx-3  mt-3  fw-bold">DESIGN AND BUILD</p>
                <img src={img3} alt="" className="Card_imge" />
                <h4 className="mx-3 fw-bold">Integration & Automation</h4>
                <span className="mx-3">Automating anything and everything</span>
                <div className="card cardtempitem">
                  <h4 className="card_title1">
                    Integration & <br /> Automation
                  </h4>
                  <h6 className="card_text2">
                    Automating anything and everything
                  </h6>
                  <p className="span_text">
                    We are increasingly living in a world of <br />
                    systems, sensors, devices, and a plethora of <br />
                    data sources. We specialise in linking disparate <br />
                    sources and automating information flow to
                    <br />
                    drive seamless integration and efficiency.
                  </p>
                  <p>
                    Unlock the immense potential of a connected <br />
                    enterprise.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div
                className="card cardtemp cardtemp4 border-0"
                style={{ borderRadius: "15px" }}
              >
                <p className="mx-3  mt-3  fw-bold">IMPLEMENTATION</p>
                <img src={img4} alt="" className="Card_imge" />
                <h4 className="mx-3 fw-bold">SAP Offerings</h4>
                <span className="mx-3 ">
                  Transforming SAP-Powered Intelligence
                </span>
                <div className="card cardtempitem">
                  <h4 className="card_title2">SAP Offerings</h4>
                  <h6 className="card_text5">
                    Transforming SAP-Powered Intelligence
                  </h6>
                  <p className="span_text">
                    Reach your transformation goals by leveraging our
                    comprehensive services, proprietary accelerators, industry
                    expertise, and preferred partnership status.
                  </p>
                  <p>
                    Together, we are digitising and transforming the supply
                    chain business with a strong commitment to amplifying SAP's
                    significant technologies and the value they bring to our
                    customers.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div
                className="card cardtemp cardtemp5 border-0"
                style={{ borderRadius: "15px" }}
              >
                <p className="mx-3 mt-3 fw-bold">IMPLEMENTATION</p>
                <img src={imgss1} alt="" className="Card_imge" />
                <h4 className="mx-3 fw-bold">Platforms Solutions</h4>
                <span className="mx-3">
                  Harnessing the Power of Best-of-Breed Platforms
                </span>
                <div className="card cardtempitem">
                  <h4 className="card_title3">Platforms Solutions</h4>
                  <h6 className="card_text3">
                    Harnessing the Power of Best-of-Breed Platforms
                  </h6>
                  <p className="span_text">
                    Integrate and implement reliable platforms
                    <br /> into your digital infrastructure to enhance your
                    <br /> supply chain and ERP environment, increasing
                    <br /> operational agility, visibility, and performance.
                  </p>
                  <p>
                    Since one size never fits all, we help companies
                    <br /> create custom digital ecosystems that are
                    <br /> enabled by the best tools available.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div
                className="card cardtemp cardtemp6 border-0"
                style={{ borderRadius: "15px" }}
              >
                <p className="mx-3 mt-3 fw-bold">IMPLEMENTATION</p>
                <img src={img6} alt="" className="Card_imge" />
                <h4 className="mx-3 fw-bold">Modern Engineering</h4>
                <span className="mx-3">Fast-Tracking Technological Change</span>
                <div className="card cardtempitem">
                  <h4 className="card_title4">Modern Engineering</h4>
                  <h6 className="card_text4">
                    Fast-Tracking Technological Change
                  </h6>
                  <p className="span_text1">
                    Develop your digital foundation and update your application
                    and data environment to maximize the value of your data.
                  </p>
                  <p className="span_text2">
                    We help our clients embrace a cloud-first mentality,
                    data-centricity, customer-centricity, and product mindset to
                    accelerate their digital transformation and keep pace with
                    the rate of change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row mt-5">
          <div className="col-12 container_design_phase demoss">
            <h3 className="fw-bold py-4">
              What follows next? It's the design phase.
            </h3>
            <p className="d-none d-lg-block">
              <span className="designcolor">Design Matters</span>, if you want
              people to use your new tools, a brilliant algorithm needs to
              <br /> be presented in an attractive package that captures
              attention.
            </p>
            <p className="d-none d-lg-block">
              Most users can’t read code or interpret the output of a model. To
              act on these insights, they
              <br /> need user-friendly dashboards that facilitate
              decision-making and scenario testing.
            </p>
            <p className="d-block d-lg-none">
              <span className="designcolor">Design Matters</span>, if you want
              people to use your new tools, a brilliant algorithm needs to be
              presented in an attractive package that captures attention.
            </p>
            <p className="d-block d-lg-none">
              Most users can’t read code or interpret the output of a model. To
              act on these insights, they need user-friendly dashboards that
              facilitate decision-making and scenario testing.
            </p>
            <button className="btn07">
              Learn More On Dashboard Experience
            </button>
          </div>
        </div>
      </div>

      <Applytoday />
      <News />
      <Footer />
    </>
  );
};

export default ModernSupplySolutions;
