import React from "react";
import "./OilandGas.css";
import vedio2 from "./Assets/Oil and Gas Video.mp4";
import laptop from "./Assets/Laptop.jpg";
import Header from "../../../../Component/Header/Header";
import News from "../../../../Component/News/News";
import Footer from "../../../../Component/Footer/Footer";

const OilandGas = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="player_wrapper01_Modern001 text-center">
              <video
                className="react_player01_Modern001"
                src={vedio2}
                loop
                autoPlay
                muted
              >
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="container con_1">
        <div className="header_01">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h3 className="performance-title">
                Achieve the performance you
                <br />
                need with the assets you have
              </h3>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <span className="bg-info1_01"> Oil and Gas Industry</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 col-12 col-md-12 text_info_txt">
            <p>
              The ongoing energy crisis is driving a renewed global focus on
              energy security, which, in turn, is driving
              <br /> the demand for fuel supply. This poses a significant market
              opportunity for upstream operators. In other
              <br /> words, to ensure the reliability of existing assets and
              maximise uptime, upstream operators must take a
              <br /> more sophisticated approach to asset performance management
              (APM).
            </p>
          </div>
        </div>
      </div>

      <div className="container con_1">
        <hr className="separator_sep" />
        <div className="row">
          <h3 className="drive_txt1">
            Drive your oil and gas business forward with integration of a
            successful Dashboard
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="case-study-content_con01">
              <div className="case-study-image_pic">
                <img src={laptop} alt="laptop img" />
              </div>
            </div>
            <span className="bg-info2_02">Case Study</span>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="case-study-text1_tx1">
              <ul>
                <li>
                  <strong>A. Company XYZ's Experience </strong> Company XYZ
                  streamlined its operations by integrating an oil and gas
                  dashboard, resulting in improved decision-making and a 20%
                  reduction in downtime.
                </li>
                <li>
                  <strong>B. Results and Positive Outcomes </strong> The
                  implementation of the dashboard led to increased operational
                  transparency, allowing Company XYZ to identify bottlenecks and
                  optimize processes for better efficiency.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 col-md-12">
            <div className="benfit_depth">
              How <span className="chnge_clr">Stephen's Analytics</span> Can
              Benefit Your Business
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 col-12 col-md-12 Open_Gain_Content1">
            <p>
              With global crude oil production reaching over 90 million barrels
              per day, Stephen's Analytics can help optimise your production by
              providing detailed reports from authoritative sources such as the
              International Energy Agency (IEA), the Ministry of Petroleum and
              Natural Gas (MoPNG), and the Petroleum Planning & Analysis Cell
              (PPAC). These insights enable precise forecasting and resource
              allocation, ensuring your business operates efficiently and
              profitably.
            </p>
          </div>

          <div className="col-lg-10 col-12 col-md-12 Open_Gain_Content2">
            <p>
              <strong>Machine intelligence</strong> enhances oil and gas data
              analytics through advanced algorithms, augmenting employee
              performance and automating complex workloads, and developing
              "cognitive agents" that simulate human thinking and engagement.
            </p>
          </div>

          <div className="col-lg-10 col-12 col-md-12 Open_Gain_Content3">
            <p>
              The intelligent use of big data analytics in the oil and gas
              industry can help perform seismic processing, reservoir modeling,
              and sensor calibration , and developing "cognitive agents" that
              simulate human thinking and engagement
            </p>
          </div>

          <div className="col-lg-10 col-12 col-md-12 Open_Gain_Content5">
            <p>
              Stay ahead with updates on exploration and production licenses
              issued under policies like{" "}
              <span className="clr_change">HELP and NELP.</span>
            </p>
          </div>

          <div className="col-lg-10 col-12 col-md-12 Open_Gain_Content4">
            <p>
              Companies like ABC Oil have successfully implemented data
              analytics to optimise drilling operations, resulting in increased
              production and reduced downtime.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid my-5">
        <div className="row ">
          <div className="col-lg-12 col-12 col-md-12 post_txt ">
            <p>
              Imagine the power of knowing how your assets will behave in
              advance, well, today it’s possible.
            </p>
          </div>
        </div>
      </div>
      <News />
      <Footer />
    </>
  );
};

export default OilandGas;
