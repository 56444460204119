import React from 'react'
import "./HealthCare.css"

const HealthCareFirst = () => {
    return (
      <div className=' ms-5 me-5 mt-5'>
       
            <p className="fs-1 mt-1 ms-5">Saving Lives, Saving Costs: Predicting <br /> Health Care with <span class="custom-color fw-bold">Stephen’s Analytics</span>
            </p>
           <div className='me-2'>
           <p className="fs-5 mt-2 ms-5 me-5">Whether it is a hospital’s attempt to optimise staffing or a government trying to fairly allocate and distribute limited doses of Covid-19 vaccines ( a historic example), these tasks can be formidable. </p>
           <p className="fs-5 mt-2 ms-5 me-5" >A promising way to manage the complexity is to enlist data-driven analytics and artificial intelligence (AI). Knowledge derived from big data analysis gives healthcare providers clinical insights not otherwise available.</p>
           </div>
      </div>
    )
}

export default HealthCareFirst