import React, { useState } from 'react';
import "./HomePagesBottom.css"
import Brand1 from "./Assets/Kiran Logo File.jpg"
import Brand2 from "./Assets/Wockhardth Logo.jpg"
import Brand3 from "./Assets/IIM Logo.png"
import Brand4 from "./Assets/Canara Logo-01.jpg"




const HomePagesBottom = () => {
   
    return (
        <>
            <div className='container mt-lg-5'>
                <div className='row mt-lg-5'>
                    <h4 className='d-flex justify-content-center centerheader fw-bold my-2 py-5'>
                        We are blessed to work with leading brands</h4>
                    <div className='col-lg-3 col-md-6 col-12 mb-3 '>
                        <div>
                            <img src={Brand1} alt="Brand1" className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 mb-3'>
                        <div>
                            <img src={Brand2} alt="Brand2" className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 mb-3'>
                        <div>
                            <img src={Brand3} alt="Brand3" className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 mb-3'>
                        <div>
                            <img src={Brand4} alt="Brand4" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default HomePagesBottom
