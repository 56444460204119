import React from "react";
import Header from "../../../Component/Header/Header";
import Footer from "../../../Component/Footer/Footer";

const ContactUsPage = () => {
  return (
    <>
      <Header />
      <div className="container-fluid mt-1 contain2">
        <div className="row row1">
          <div className="col-lg-5 mb-4">
            <div className="p-4 ">
              <h2 className="h2-edit">About Us</h2>
              <ul className="list-unstyled items">
                <a href="/Clientele">Clientele</a>
                <br />
                <a href="/TopNews">Top News</a>
                <br />
              </ul>
            </div>
          </div>
          <div className="col-lg-7 mb-4">
            <div className="p-4">
              <h2 className="h2-edit">Help</h2>
              <ul className="list-unstyled items">
                <a href="/ApplyJobs">Apply for jobs</a>
                <br />
                <a href="/DataPolicy">Data Protection Policy</a>
                <br />
                <a href="/FAQs">FAQs</a>
                <br />
                <a href="/ContactUs">Contact Us</a>
                <br />
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUsPage;
