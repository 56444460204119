import React from 'react'
import HealthCareFirst from './HealthCareFirst';
import HealthCareImages from './HealthCareImages';
import HealthCareDetail from './HealthCareDetail';
import HealthCareDetails from './HealthCareDetails';
import HealthCareTheroryDetails from './HealthCareTheroryDetails';
import HealthCareFrom from './HealthCareFrom';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
import Applytoday from '../../Component/Applytoday/Applytoday';

const HealthCarePage = () => {
    return (
        <>
        <Header />
            <HealthCareFirst />
            <HealthCareImages />
            <HealthCareDetail />
            <HealthCareDetails />
            <HealthCareTheroryDetails />
          <Applytoday />
        <Footer />
        </>
    )
}

export default HealthCarePage