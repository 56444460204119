import React from "react";
import "./HealthCare1.css";

const HealthCareImages = () => {
  return (
    <>
      <div className="container-fluid" id="container_id">
        <div className="slidess mx-lg-2">
          <div className="text-overlay">
            <p>Text for Slide 1</p>
          </div>
        </div>
        <div className="slidess mx-lg-1">
          <div className="text-overlay">
            <p>Text for Slide 2</p>
          </div>
        </div>
        <div className="slidess mx-lg-1">
          <div className="text-overlay">
            <p>Text for Slide 3</p>
          </div>
        </div>
        <div className="slidess mx-lg-1">
          <div className="text-overlay">
            <p>Text for Slide 4</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthCareImages;
