import React from 'react'
import "./HealthCare.css"

const HealthCareDetail = () => {
    return (
        <div>
            <div className=' ms-5 me-5 mb-5'>
                <p className='fontSize custom-color fw-bold ms-5 '>What are the promises of big data analytics in healthcare?</p>
                <div className="fs-5 mt-3 ms-5 me-5 " >
                    <p>Healthier patients, lower care costs, more visibility into performance, and higher staff and consumer satisfaction rates are among the many benefits of turning data assets into data insights.</p>

                    <p>Providers can start to improve their data capture routines by prioritising valuable data types – EHRs, genomic data, population-level information – for their specific projects, enlisting the data governance and integrity expertise of health information management professionals, and developing clinical documentation improvement programs that coach clinicians about how to ensure that data are useful for downstream analytics.</p>

                </div>
            </div>
        </div>
    )
}

export default HealthCareDetail