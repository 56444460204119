import React from "react";
import "./LandScapDetails.css";

const LandScapeDetails = () => {
  return (
    <div>
      <div className="head-line">
        <h5>The Complex Landscape of Generative AI Costs</h5>
      </div>
      <div className="details-in-page">
        <p>
          <span>Inference Cost:</span> The inference cost is the cost of calling
          a large language model (LLM) to generate a response. Each instance
          here you furnish an input (the prompt) to an LLM to yield an output
          (the completion) incurs the utilisation of compute resources. This act
          of engaging a trained LLM to produce an output is formally termed
          "inference." At its core, this process is underpinned by GPU compute,
          constituting what is known as the inference cost. For example, the
          inference cost of generating a text completion from a LLM such as
          GPT-4 is typically around $0.006 per 1,000 output tokens plus $0.003
          per 1,000 input tokens. Tokens are groups of words, where 1,000 tokens
          are roughly 750 words. The inference cost of generating an image from
          a large image generation model such as DALL-E 2 is typiclly around
          $0.18 per image
        </p>
        <p>
          The inference cost of generative Al can be a significant barrier to
          adoption, especially for businesses that need to generate large
          volumes of content. However, there are a number of ways to reduce the
          inference cost of generative Al, such as: using a smaller model,
          hosting an Open-Source LLM, optimising the inference process. CEOS can
          formulate optimal strategies by organising engineers and consultants
          to conduct a comparative analysis of these different approaches,
          weighing their advantages and costs meticulously.{" "}
        </p>
        <p>
          <span>Fine-tuning Cost:</span> Fine-tuning is the process of adapting
          a pre-trained generative Al model to a specific task or domain. This
          involves training the model on a new dataset that is specific to the
          desired task or domain. The fine-tuning cost of generative Al depends
          on a number of factors, including: the size and complexity of the
          model, the amount of data used for fine-tuning, and the number of
          epochs (iterations) of training
        </p>
        <p>
          <span>Prompt Engineering Cost:</span> Prompt engineering is the
          process of structuring text that can be interpreted and understood by
          a GenAI model. Effective prompt engineering can lead to significant
          improvements in the quality and relevance of the model’s output. The
          expenses linked with the development and utilisation of prompts to
          enhance the performance of GenAI models could indeed be substantial.
          Such investments necessitate careful consideration and strategic
          planning to optimise their impact on overall business outcomes.
        </p>
        <p>
          <span>Cloud Expense:</span> When CEOs contemplate cloud expenses, it’s
          imperative to look beyond the mere hosting costs of GenAI
          applications. Instead, a holistic view of the overall cloud
          architecture posts the implementation of GenAI strategies is
          essential. Take, for instance, the healthcare sector. Medical
          institutions cannot simply upload patient data to remote locations. In
          such scenarios, expanding local private cloud storage becomes
          imperative when dealing with the massive user-related data generated
          by GenAI-driven chatbots in customer service. <br />
          Additionally, some companies are yet to make a definitive choice among
          public cloud, private cloud, and multi-cloud solutions. CEOs, in
          crafting GenAI strategies, must refine their cloud strategies
          accordingly.
        </p>
        <p>
          <span>Operation Costs:</span> Machine learning operations (MLOps)
          embodies a suite of practices meticulously designed to streamline
          workflow processes and automate machine learning and deep learning
          deployments. It accomplishes the deployment and maintenance of models
          reliably and efficiently for production, at a large scale. Within the
          realm of AI initiatives, especially when contemplating expansion and
          the transition of models into production, MLOps stands as a pivotal
          cost-reducing force. Its impact ripples throughout the machine
          learning lifecycle, chiefly by automating tasks that would otherwise
          demand substantial manual efforts. This not only simplifies the
          process but also enhances error detection mechanisms and elevates
          model management standards.
        </p>
        <p>
          <span>Talent Costs:</span> Talent serves as the cornerstone of GenAI
          strategies. While the industry consensus acknowledges GenAI’s
          significant potential in enhancing efficiency across various roles,
          CEOs must exercise caution to avoid a short-term rush for talent,
          which could lead to a dramatic escalation in GenAI talent costs. In
          the long run, GenAI is poised to create entirely new job categories.
          CEOs, in collaboration with CHROs, must swiftly develop medium and
          long-term talent plans to adapt to the evolving work landscape driven
          by GenAI.
        </p>
      </div>
    </div>
  );
};

export default LandScapeDetails;
