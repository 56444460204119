import React from "react";
import "./Contactus.css";
import img1 from "./Assets/WhatsappLogo.png";

import vedio from "./Assets/ContactUs-2.mp4";

import { PopupButton } from "react-calendly";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";

const Contactusf = () => {
  return (
    <>
      <Header />
      <div className="container-fluid container_fluid_contactus">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex Contact_content">
            <div className="player_01 text-center">
              <video className="react_01  " src={vedio} loop autoPlay muted>
                Your browser does not support HTML video.
              </video>
            </div>
            <div className="Contact_content2">
              <p>
                Sit back and relax while we get <br/>  you your{" "}
               
                <button class="custom-btn btn-11">
                  New-Gen Ai toy.<div class="dot"></div>
                </button>{" "}
                <br/> 
                <span>Thus, the Power shift to you</span>
              </p>
            </div>
          </div>
        </div>










        <div className="row address_Content_All mt-lg-5">
        <div className="col-lg-5 col-md-12 col-12 d-lg-flex justify-content-end">
  <div className="address_Content_1 d-flex flex-row flex-lg-column">
    <div className="button-wrapper">
      <PopupButton
        url="https://calendly.com/stephenshouse-in/30min"
        rootElement={document.getElementById("root")}
        text="Schedule a Call "
        className="btn-14"
      />
    </div>
    <div className="button-wrapper">
      <button
        className="custom-btn btn-14"
        onClick={() => {
          document
            .getElementById("query")
            .scrollIntoView({ behavior: "smooth" });
        }}
      >
        Drop in a query
      </button>
    </div>
  </div>
</div>

          <div className="col-lg-5 col-md-12 col-12">
            <div className="address_Content_2 ">
              <ol>
                <li>
                  <span className="Content_13">Tap here to open</span>{" "}
                  {/* <img src={img1} alt="" className="img-fluid" /> */}
                  <i class="bi bi-whatsapp mx-1 px-1 fs-4 text-success"></i> <span className="fw-bold">WhatsApp</span>
                </li>
                <li>
                  <p>
                    Ph-number{" "}
                    <span className="Content_11">
                      : +91-9967776771{" "}
                      <span className="Content_12">
                        <i className="bi bi-copy"> Copy phone number</i>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    Corporate office{" "}
                    <span className="px-1">
                      : <i class="bi bi-geo-alt-fill  text-danger "></i>First
                      floor , B Wing,Brigade Tech{" "}
                      <span className="d-lg-flex justify-content-center">
                        Park, Whitefield 556066.
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>








        <div className="row" id="query">
          <div className="col-lg-12">
            <div className="formbold-main-wrapper">
              <div className="formbold-form-wrapper">
                <div className="formbold-steps">
                  <h2>We appreciate you contacting us.</h2>
                </div>
                <h5 className="hello">TELL US MORE ABOUT YOUR BUSINESS</h5>
                <div className="formbold-form-step-1 my-5 active">
                  <div className="formbold-input-flex">
                    <div>
                      <label
                        htmlFor="firstname"
                        className="formbold-form-label"
                      >
                        First name: <span className="star_color"> *</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        placeholder=""
                        id="firstname"
                        className="formbold-form-input"
                        style={{
                          border: "1px solid #A9A9A9",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="lastname" className="formbold-form-label">
                        Last name: <span className="star_color"> *</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        placeholder=""
                        id="lastname"
                        className="formbold-form-input"
                        style={{
                          border: "1px solid #A9A9A9",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="formbold-input-flex">
                    <div>
                      <label htmlFor="email" className="formbold-form-label">
                        Work Email Address:{" "}
                        <span className="star_color"> *</span>{" "}
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder=""
                        id="email"
                        className="formbold-form-input"
                        style={{
                          border: "1px solid #A9A9A9",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="formbold-form-label">
                        {" "}
                        Phone Number: <span className="star_color">
                          {" "}
                          *
                        </span>{" "}
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        className="formbold-form-input"
                        style={{
                          border: "1px solid #A9A9A9",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="formbold-input-flex">
                  <div className="formbold_Content1">
                    <label htmlFor="Company:" className="formbold-form-label">
                      Company:<span className="star_color"> *</span>{" "}
                    </label>
                    <input
                      type="text"
                      name="company"
                      placeholder=""
                      id="company"
                      className="formbold-form-input"
                      style={{
                        border: "1px solid #A9A9A9",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div className="formbold_Content1">
                    <label htmlFor="Country" className="formbold-form-label">
                      Country: <span className="star_color"> *</span>{" "}
                    </label>
                    <input
                      type="text"
                      name="country"
                      placeholder=""
                      id="country"
                      className="formbold-form-input"
                      style={{
                        border: "1px solid #A9A9A9",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                </div>

                <div className="formbold-form-step-2">
                  <div>
                    <label htmlFor="message" className="formbold-form-label">
                      Help us outlining the project objective:
                    </label>
                    <textarea
                      rows="6"
                      name="message"
                      id="message"
                      placeholder="Let us know any additional information that is relevant for your business requirements"
                      className="formbold-form-input1 small-placeholder"
                      style={{
                        border: "1px solid #A9A9A9",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="formbold-form-btn-wrapper">
                  <button type="button" className="formbold-back-btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactusf;
