import React from "react";
import Header from "../../../Component/Header/Header";
import Footer from "../../../Component/Footer/Footer";

const InsightsPage = () => {
  return (
    <>
      <Header />
    
      <Footer />
    </>
  );
};

export default InsightsPage;

{
  /* <a href="/AdoptingGenAI">
                  What CEOs Need to Know About the Costs of Adopting GenAI
                </a>
                <a href="/WhyStephensAnyts">Why Stephens's Analytics</a> */
}
