import React, { useState } from "react";
import img1 from "../../Component/Header/Assets/SHOD Logo-01.png";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const [isVisible, setIsVisible] = useState(true);




  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick = (contentName) => {
    setActiveContent(contentName);
  };

  const closeOverlay = () => {
    setActiveContent(""); // Reset active content to close overlay
  };

  return (
    <div className="container container12">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 Header_Contant my-3">
          <div className="img_logo">
            <a href="/">
              <img src={img1} alt="Logo" />
            </a>
          </div>

          <div className={`offcanvas-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="offcanvas-header">
              <span className="close-button" onClick={toggleMenu}>×</span>
            </div>
            <div className="offcanvas-content">
              <a href="/Parallex">HOUSE</a>
              <a href="/SolutionPage">SOLUTIONS</a>
              <a href="/Insights">INSIGHTS</a>
              <a href="/Contact">CONTACT US</a>
              <button>Schedule a call</button>
            </div>
          </div>

          <div className="toggle-button" onClick={toggleMenu}>☰</div>

          <div className="large_screen text-center">
            <div className="atagmove">
              <a href="/" className="home_btn">HOUSE</a>
              <a href="#" onClick={() => handleClick("solution")} className={activeContent === "solution" ? "active" : ""}>SOLUTIONS</a>
              <a href="#" onClick={() => handleClick("insight")} className={activeContent === "insight" ? "active" : ""}>INSIGHTS</a>
              <a href="#" onClick={() => handleClick("contact")} className={activeContent === "contact" ? "active" : ""}>CONTACT US</a>
            </div>
            <button>Schedule a call</button>
          </div>

          {/* Overlay for Contact Us */}
          {activeContent && (
            <div className="overlay" onClick={closeOverlay}>
              <div className="overlay-content" onClick={(e) => e.stopPropagation()}> {/* Prevent click event from closing the overlay */}
                <span className="close-button" onClick={closeOverlay}>×</span>
                {activeContent === "contact" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit">About Us</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/Clientele">Clientele</a></li>
                            <li><a href="/TopNews">Top News</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit">Help</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/ApplyJobs">Apply for jobs</a></li>
                            <li><a href="/DataPolicy">Data Protection Policy</a></li>
                            <li><a href="/FAQs">FAQs</a></li>
                            <li><a href="/ContactUs">Contact Us</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeContent === "insight" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-1">
                        <div className="p-4">
                          <h2 className="h2-edit">Comprehension</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/WhyStephensAnyts">Why Stephens's Analytics</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit">Blogs</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/AdoptingGenAI">What CEOs Need to Know About the Costs of Adopting GenAI</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeContent === "solution" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-1">
                        <div className="p-4">
                          <h2 className="h2-edit">Services</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/ModernSupplySolutions">Modern Supply Chain Solutions</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit">Industries</h2>
                          <ul className="list-unstyled items">
                            <li><a href="/Banking">Banking</a></li>
                            <li><a href="/HealthCarePage">Health Care</a></li>
                            <li><a href="/OilAndGas">Oil & Gas</a></li>
                            <li><a href="/ViewAll">View all industries</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
